import { FilterWrapper } from "../Style/SearchStyles";
import {
  PubTypeLabels,
  PubSortTypeLabels,
  getPubSortLabel,
  getPubTypeLabel,
  GameTypeLabels,
  getGameTypeLabel,
} from "../../../../utils/constants";
import { useSearchContext } from "../Hook/SearchContext";
import { GameType, PubType } from "../../../../api/types";
import { useCallback, useEffect, useState } from "react";

export const FilterComponent = () => {
  const {
    premiumList,
    setShowFilter,
    filter,
    isSelectedPubType,
    isSelectedSortType,
    isSelectedGameType,
    setPubType,
    setSortType,
    setGameType,
  } = useSearchContext();

  const [selectedPubType, selectPubType] = useState<PubType>(
    filter.pubTypes ? filter.pubTypes[0] : ""
  );
  const [selectedGameType, selectGameType] = useState<GameType>(
    filter.gameTypes ? filter.gameTypes[0] : ""
  );

  const [selectedSortType, selectSortType] = useState<string>("distance");
  const [showPubFilter, setShowPubFilter] = useState(false);
  const [showGameFilter, setShowGameFilter] = useState(false);
  const [showSortFilter, setShowSortFilter] = useState(false);

  useEffect(() => {
    selectPubType(filter.pubTypes ? filter.pubTypes[0] : "");
    selectGameType(filter.gameTypes ? filter.gameTypes[0] : "");
    selectSortType(filter.sort ? filter.sort : "distance");
  }, [filter]);
  return (
    <FilterWrapper show={premiumList.length > 0}>
      <div className="title">
        원하시는 홀덤펍을
        <br />
        찾아보세요!
      </div>
      <div className="type-row">
        <img
          id="초기화"
          alt="초기화"
          className="refresh"
          src={`/image-web/search/refresh.svg`}
          onClick={() => {
            setSortType("distance");
            selectSortType("distance");
            setPubType("");
            setGameType("");
            selectPubType("");
            selectGameType("");
          }}
        />
        <div className="sort-box">
          <div
            className="sort-wrapper"
            onClick={() => {
              setShowSortFilter(false);
              setShowGameFilter(!showGameFilter);
              setShowPubFilter(false);
            }}
          >
            <span
              className={`title ${
                getGameTypeLabel(selectedGameType).length > 5 && "long"
              }`}
            >
              {showGameFilter
                ? "게임 종류"
                : getGameTypeLabel(selectedGameType)}
            </span>
            <img src="/image-web/Icon/Arrow%20down.svg" alt="Arrow down" />
            {showGameFilter && (
              <div className="sort-popup">
                {GameTypeLabels.map(({ type, label }, i) => (
                  <div
                    className={`item ${
                      isSelectedGameType(type) ? "selected" : ""
                    }`}
                    key={i}
                    onClick={() => {
                      setGameType(type);
                      selectGameType(type);
                    }}
                  >
                    {label}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div
            className="sort-wrapper"
            onClick={() => {
              setShowSortFilter(false);
              setShowGameFilter(false);
              setShowPubFilter(!showPubFilter);
            }}
          >
            <span
              className={`title ${
                showPubFilter
                  ? "long"
                  : getPubTypeLabel(selectedPubType).length > 5 && "long"
              }`}
            >
              {showPubFilter ? "홀덤펍 종류" : getPubTypeLabel(selectedPubType)}
            </span>
            <img src="/image-web/Icon/Arrow%20down.svg" alt="Arrow down" />
            {showPubFilter && (
              <div className="sort-popup">
                {PubTypeLabels.map(({ type, label }, i) => (
                  <div
                    className={`item ${
                      isSelectedPubType(type) ? "selected" : ""
                    }`}
                    key={i}
                    onClick={() => {
                      setPubType(type);
                      selectPubType(type);
                    }}
                  >
                    {label}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div
            className="sort-wrapper"
            onClick={() => {
              setShowPubFilter(false);
              setShowGameFilter(false);
              setShowSortFilter(!showSortFilter);
            }}
          >
            <span
              className={`title ${
                getPubSortLabel(selectedSortType).length > 5 && "long"
              }`}
            >
              {showSortFilter ? "정렬기준" : getPubSortLabel(selectedSortType)}
            </span>
            <img src="/image-web/Icon/Arrow%20down.svg" alt="Arrow down" />
            {showSortFilter && (
              <div className="sort-popup">
                {PubSortTypeLabels.map(({ type, label }, i) => (
                  <div
                    className={`item ${
                      isSelectedSortType(type) ? "selected" : ""
                    }`}
                    key={i}
                    onClick={() => setSortType(type)}
                  >
                    {label}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <img
          id="상세"
          alt="상세"
          className="detail"
          src={`/image-web/search/detail.svg`}
          onClick={() => setShowFilter(true)}
        />
      </div>
    </FilterWrapper>
  );
};
