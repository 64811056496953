import React from "react";
import { SearchProvider } from "./Hook/SearchContext";
import SearchContent from "./SearchContent";

const Search: React.FC = () => {
  return (
    <SearchProvider>
      <SearchContent />
    </SearchProvider>
  );
};

export default Search;
