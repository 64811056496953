import styled from "styled-components";
import { SecondHandMarketResponse } from "../../../api/second-hand-market";
import { useHistory } from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > .header {
    padding: 0 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > .title {
      color: #000;

      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: -0.4px;
    }

    > .more {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;
      cursor: pointer;
      z-index: 2;
      > img {
        width: 16px;
        height: 16px;
      }

      > span {
        color: var(--Black-200, #b7b7b7);
        text-align: right;
        font-family: Pretendard;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.26px;
      }
    }
  }

  > .list {
    width: 100%;
    padding: 12px 17px;
    display: flex;
    flex-wrap: wrap;
    margin-top: -10px;
  }

  .list > * {
    margin-top: 10px;
    margin-right: 8px;
  }

  .list > *:nth-child(2n) {
    margin-right: 0;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: calc(50% - 4px);

  box-sizing: border-box;
  > .cover {
    width: 100%;
    padding-bottom: 100%;
    position: relative;

    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover;
    }
  }

  > .bottom {
    width: 100%;
    height: 55px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    > .title {
      height: 35px;
      color: #000;
      font-family: Pretendard;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > .price {
      color: #000;
      font-family: Pretendard;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
`;
const Market = (props: { list: SecondHandMarketResponse[] }) => {
  const list = props.list;
  const history = useHistory();

  return (
    <Wrapper>
      <div className="header">
        <div className="title">홀덤 중고장터</div>
        <div
          className="more"
          onClick={() => {
            history.push("/second-hand-market");
          }}
        >
          <img src="/image-web/home/more.svg" alt="more" />
          <span>더보기</span>
        </div>
      </div>
      <div className="list">
        {list.map((item, index) => (
          <Item
            onClick={() => {
              history.push(`/second-hand-market/detail/${item.id}`);
            }}
          >
            <div className="cover">
              <img src={item.imageList[0]} />
            </div>
            <div className="bottom">
              <div className="title">{item.title}</div>
              <div className="price">{`${item.price.toLocaleString()}원`}</div>
            </div>
          </Item>
        ))}
      </div>
    </Wrapper>
  );
};

export default Market;
