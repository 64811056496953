import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import MainPage from "./main";
import BestPage from "./best";
import NoticePage from "./notice";
import WritePage from "./write";
import { LOCAL_STORAGE_ACCESS_KEY } from "../../../utils/network";
import { enqueueSnackbar } from "notistack";
import { userState } from "../../../recoil/auth";
import { useRecoilValue } from "recoil";

const Header = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background: white;
  z-index: 11;
  flex-direction: row;

  .left {
    display: flex;
    align-items: center;
    .close {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }

    .title {
      margin-left: 5px;
      color: #444;
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .right {
    img {
      width: 20px;
      height: 20px;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  > .header {
    background-color: white;
    width: 100%;
    position: fixed;
    padding-top: 58px;
    display: flex;
    flex-direction: row;
  }
`;

interface HeaderTitleProps {
  active: boolean;
}

const HeaderTitle = styled.div<HeaderTitleProps>`
  padding: 0px 0px 10px;
  flex: 1;
  color: #dedede;
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-bottom: 1px solid #dedede;
  cursor: pointer;

  ${(props) =>
    props.active &&
    css`
      color: var(--Black-400, #444);
      border-bottom: 1px solid black;
    `}
`;

const WriteButton = styled.div`
  z-index: 10;
  position: fixed;
  right: 16px;
  bottom: 50px;
  width: 50px;
  height: 50px;
  background-color: var(--Purple-300, #6436e7);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    position: absolute;
    width: 70%;
    height: 70%;
  }
`;

type PageType = "main" | "best" | "notice";

const Article: React.FC = () => {
  const history = useHistory();
  const [page, setPage] = useState<PageType>("main");
  const [showWrite, setShowWrite] = useState(false);
  const user = useRecoilValue(userState);
  return (
    <Wrapper>
      <Header>
        <div className="left">
          <div
            className="close"
            onClick={() => {
              history.goBack();
            }}
          >
            <img
              src="/image-web/Icon/Back.svg"
              id="핸드게시판 뒤로가기"
              alt="close"
            />
          </div>
          <div className="title">핸드게시판</div>
        </div>
        <div className="right"></div>
      </Header>
      <div className="header">
        <HeaderTitle
          active={page === "main"}
          onClick={() => {
            setPage("main");
          }}
        >
          전체
        </HeaderTitle>
        <HeaderTitle
          active={page === "best"}
          onClick={() => {
            setPage("best");
          }}
        >
          베스트
        </HeaderTitle>
        <HeaderTitle
          active={page === "notice"}
          onClick={() => setPage("notice")}
        >
          공지
        </HeaderTitle>
      </div>
      <div className="content">
        {" "}
        {page === "main" && <MainPage showWrite={showWrite} />}
        {page === "best" && <BestPage />}
        {page === "notice" && <NoticePage />}
      </div>

      {showWrite && <WritePage onClose={setShowWrite} />}
      <WriteButton
        onClick={() => {
          const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_KEY);
          if (!accessToken) {
            history.push("/login", { redirect: window.location.href });
            enqueueSnackbar("로그인이 필요한 서비스 입니다.", {
              variant: "error",
            });
          } else {
            if (user?.validate) {
              setShowWrite(true);
            } else {
              enqueueSnackbar("본인인증이 필요한 서비스입니다.", {
                variant: "error",
              });
            }
          }
        }}
      >
        <img src="/image-web/market/write.svg" />
      </WriteButton>
    </Wrapper>
  );
};

export default Article;
