import { useState, useCallback, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { geoCoordState } from "../../../../recoil/geo";
import { currentTimeState, loadingState } from "../../../../recoil/app";
import { navigationTargetState } from "../../../../recoil/store";
import { navigatorShadowState } from "../../../../recoil/navigator";
import { usePubSearch } from "./usePubSearch";
import { useMapManagement } from "./useMapManagement";
import { useMarkerManagement } from "./useMarkerManagement";
import { useLocationMarker } from "./useLocationMarker";
import { useStoreManagement } from "./useStoreManagement";
import useGeoLocation from "../../../../hooks/useGeoLocation";
import useCities from "../../../../hooks/useCities";
import useQuickButtons from "../../../../hooks/useQuickButtons";
import useDialog from "../../../../hooks/useDialog";
import usePubSearchFilter from "../../../../hooks/usePubSearchFilter";
import useQueryParams from "../../../../hooks/useQueryParams";
import { parseJSON } from "../../../../utils/common";
import { Cafe } from "../../../../api/types";

const NaverMaps = (window as any).naver.maps;

export const useSearch = () => {
  const history = useHistory();
  const currentTime = useRecoilValue(currentTimeState);
  const location = useLocation<{ mode?: "query" | "area" | "premium" }>();
  const [showRegisterPremium, setShowRegisterPremium] = useState(false);

  const {
    storeList,
    premiumList,
    searchResults,
    handleSearch,
    handleTextSearch,
  } = usePubSearch();

  const [selectedStore, setSelectedStore] = useState<Cafe | null>(null);
  const [showFilter, setShowFilter] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showMoreOption, setShowMoreOption] = useState(false);
  const [showLocationFilter, setShowLocationFilter] = useState(false);
  const [showStoreList, setShowStoreList] = useState(true);
  const [cityText, setCityText] = useState("서울특별시 강남구");
  const [isArea, setIsArea] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // Recoil 상태들
  const [navigatorShadow, setNavigatorShadow] =
    useRecoilState(navigatorShadowState);
  const setNavigationTarget = useSetRecoilState(navigationTargetState);
  const setLoading = useSetRecoilState(loadingState);
  const { latitude, longitude, accuracy } = useRecoilValue(geoCoordState);

  const query = useQueryParams();
  const { updateLocation } = useGeoLocation();
  const { provinces, cities, findClosestCity, getAddressByCoord } = useCities();
  const { openPhoneCall, toggleLike, shareLink } = useQuickButtons();
  const { openDialog } = useDialog();
  const {
    filter,
    setFilter,
    togglePubType,
    toggleGameType,
    setSortType,
    setPubType,
    setGameType,
    isSelectedPubType,
    isSelectedGameType,
    isSelectedSortType,
  } = usePubSearchFilter();

  // selectedStore 업데이트를 위한 ref
  const previousStoreIdRef = useRef<number | null>(null);
  const processedModeRef = useRef<string | null>(null);
  // Map 관리 hook
  const { mapRef, mapCenter } = useMapManagement({
    latitude,
    longitude,
    query,
    handleSearch,
    filter,
    history,
    location,
    isArea,
    setIsArea,
    setCityText,
    getAddressByCoord,
    currentTime,
  });

  // Marker 관리 hook
  const { hoverStoreId, setHoverStoreId, selectedStoreId, setSelectedStoreId } =
    useMarkerManagement(
      mapRef,
      storeList,
      premiumList,
      showMoreOption,
      setShowMoreOption
    );

  // Location marker 관리 hook
  const myLocMarkerRef = useLocationMarker(
    mapRef,
    latitude,
    longitude,
    accuracy
  );

  // Store 관리 hook
  const { handleLikeItem, handleSelectCity } = useStoreManagement({
    toggleLike,
    storeList,
    mapRef,
    setCityText,
    setShowLocationFilter,
    setIsArea,
  });

  // 리스트 토글
  const toggleShowList = useCallback(() => {
    setShowStoreList((prev) => {
      const show = !prev;
      setNavigatorShadow(show);

      // view 쿼리 파라미터 업데이트
      const newQuery = new URLSearchParams(location.search);
      newQuery.set("view", show ? "list" : "map");

      // history 업데이트
      history.replace({
        pathname: location.pathname,
        search: newQuery.toString(),
        state: location.state,
      });

      return show;
    });
  }, [history, location.pathname, location.state, setNavigatorShadow]);

  const handleClickResult = useCallback((cafe: Cafe) => {
    setShowStoreList(false);
    setNavigatorShadow(false);
    if (mapRef.current) {
      mapRef.current.setCenter(new NaverMaps.LatLng(cafe.lat, cafe.lon));
    }
    setSelectedStore(cafe);
    setShowFilter(false);
  }, []);

  // selectedStore 업데이트 로직 개선
  useEffect(() => {
    if (selectedStoreId !== previousStoreIdRef.current) {
      previousStoreIdRef.current = selectedStoreId;

      const store =
        premiumList.find((item) => item?.id === selectedStoreId) ||
        storeList.find((item) => item?.id === selectedStoreId);

      if (store) {
        setSelectedStore(store);
      }
    }
  }, [selectedStoreId, premiumList, storeList]);

  useEffect(() => {
    if (
      !location.state?.mode ||
      processedModeRef.current === location.state.mode
    ) {
      return;
    }

    const { mode, ...restState } = location.state;
    processedModeRef.current = mode;

    // 먼저 state를 정리
    history.replace({
      pathname: location.pathname,
      search: location.search,
      state: restState,
    });

    // 그 다음 mode에 따른 동작 수행
    switch (mode) {
      case "query":
        setShowSearch(true);
        break;
      case "area":
        query.set("view", "list");
        setShowLocationFilter(true);
        break;
      case "premium":
        toggleShowList();
        setFilter((prev) => ({
          ...prev,
          pubTypes: ["PREMIUM"],
        }));
        break;
    }
  }, [history, location, query, setFilter, toggleShowList]);

  // 초기 필터 설정 (한 번만 실행)
  useEffect(() => {
    const initialFilter = parseJSON(query.get("filter"));
    if (initialFilter) {
      setFilter(initialFilter);
    }
  }, []); // 의존성 배열을 비워서 초기 마운트시에만 실행

  // 내 위치 업데이트
  const updateMyLocation = useCallback(async () => {
    const result = await updateLocation();
    if (mapRef.current) {
      mapRef.current.setCenter(
        new NaverMaps.LatLng(result.latitude, result.longitude)
      );
    }
  }, [updateLocation]);

  const handleMoreOption = useCallback((cafe: Cafe) => {
    setSelectedStore(cafe);
    setShowMoreOption(true);
  }, []);

  // 초기 위치 설정 및 iOS 위치 권한 체크
  useEffect(() => {
    updateMyLocation();
    if (latitude === 37.493743896484375 && longitude === 127.0637597) {
      openDialog({
        title: "정확한 위치(ios)",
        type: "web",
        text: "더욱 정확한 위치 정보를 얻기 위해<br>아래와 같이 설정하세요.<br><br>설정 -> 러너러너 -> 위치 -> 정확한 위치 허용",
        style: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        },
      });
    }
  }, []);

  return {
    isScrolled,
    currentTime,
    setIsScrolled,
    history,
    showRegisterPremium,
    setShowRegisterPremium,
    showSearch,
    setShowSearch,
    showFilter,
    setShowFilter,
    showLocationFilter,
    setShowLocationFilter,
    showStoreList,
    setShowStoreList,
    premiumList,
    storeList,
    selectedStore,
    setSelectedStore,
    filter,
    cityText,
    provinces,
    cities,
    handleSearch,
    handleSelectCity,
    handleClickResult,
    handleLikeItem,
    updateMyLocation,
    toggleShowList,
    isSelectedPubType,
    isSelectedSortType,
    isSelectedGameType,
    toggleGameType,
    togglePubType,
    setSortType,
    setFilter,
    setPubType,
    setGameType,
    openPhoneCall,
    shareLink,
    setNavigationTarget,
    latitude,
    longitude,
    handleMoreOption,
    showMoreOption,
    setShowMoreOption,
    mapCenter,
    searchResults,
    handleTextSearch,
  };
};
