import instance from "../utils/network";
import { ArticleResponse } from "./dashboard";

export interface articleRequest {
  title: string;
  content: string;
}

export interface commentRequest {
  parentId?: number | null;
  content: string;
}

export interface articleMainResponse {
  articles: ArticleResponse[];
  notices: ArticleResponse[];
}

export interface articleReportRequest {
  articleId: number;
  type: string;
  description: string;
}

export interface articleBlockRequest {
  blockUserId: number;
}

export interface articleCommentReportRequest {
  commentId: number;
  type: string;
  description: string;
}
export function uploadArticleImage(formData: FormData) {
  return instance.post("/board/upload", formData);
}

export function blockArticleUser(request: articleBlockRequest) {
  return instance.post("/board/block", request);
}

export function uploadBoard(
  body: articleRequest
): Promise<{ code: number; message: string }> {
  return instance.post("/board/articles", body);
}

export function updateBoard(body: articleRequest, id: number) {
  return instance.put(`/board/articles/${id}`, body);
}

export function updateComment(body: commentRequest, id: number) {
  return instance.put(`/board/comments/${id}`, body);
}

export function reportBoard(body: articleReportRequest) {
  return instance.put(`/board/articles/report`, body);
}

export function reportComment(body: articleCommentReportRequest) {
  return instance.put(`/board/articles-comment/report`, body);
}

export function removeComment(id: number) {
  return instance.delete(`/board/comments/${id}`);
}

export function registerComment(
  id: number,
  body: commentRequest
): Promise<{ code: number; message: string }> {
  return instance.post(`/board/articles/${id}/comments`, body);
}
export function removeBoard(id: number) {
  return instance.delete(`/board/articles/${id}`);
}

export function getBoardMain(): Promise<articleMainResponse> {
  return instance.get("/board/articles");
}

export function getBoardBest(): Promise<ArticleResponse[]> {
  return instance.get("/board/articles/best");
}

export function getBoardDetail(id: number): Promise<ArticleResponse> {
  return instance.get(`/board/articles/${id}`);
}

export function getNotices(): Promise<ArticleResponse[]> {
  return instance.get("/board/articles/notices");
}
