import React, { useCallback, useEffect, useState } from "react";

import {
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addDays,
  isSameMonth,
  isSameDay,
  parse,
  getYear,
  getMonth,
} from "date-fns";
import {
  getDayMajorTournamentList,
  getMajorTournamentList,
  TournamentScheduleItemInterface,
} from "../../../api/tournament";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../recoil/app";
import TournamentItem from "./TournamentItem";
import {
  CalendarWrapper,
  Cell,
  Column,
  RedDot,
  Row,
  Selectors,
  SelectRowWRapper,
  TournamentWrapper,
  Wrapper,
  Number,
  DotWrapper,
  GreenDot,
  TournamentHeaderWrapper,
  DotInfoWrapper,
  TabWrapper,
  TabButton,
} from "./styles";

const MTournamentSchedule = () => {
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [isTotal, setIsTotal] = useState(true);
  const [listData, setListData] = useState<TournamentScheduleItemInterface[]>(
    []
  );
  const [selectedListData, setSelectedListData] = useState<
    TournamentScheduleItemInterface[]
  >([]);
  const setLoading = useSetRecoilState(loadingState);
  const [tab, setTab] = useState("DOMESTIC");
  const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
  const months = [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ];

  const setSortedSelectedListData = useCallback(
    (res: TournamentScheduleItemInterface[]) => {
      const tabList = res.filter((item) => item.competitionType === tab);
      const now = new Date();
      const endedList = tabList.filter(
        (tournament) => new Date(tournament.endAt) < now
      );
      const notEndedList = tabList
        .filter((tournament) => new Date(tournament.endAt) >= now)
        .sort((a, b) => {
          return new Date(a.startAt).getTime() - new Date(b.startAt).getTime();
        });
      setSelectedListData(notEndedList.concat(endedList));
    },
    []
  );

  const fetchMonthData = useCallback(
    async (date: Date) => {
      const selectedYear = getYear(date);
      const selectedMonth = getMonth(date) + 1;

      setLoading(true);
      try {
        const res = await getMajorTournamentList({
          year: selectedYear,
          month: selectedMonth,
        });
        setListData(res);

        if (isTotal) {
          setSortedSelectedListData(res);
        }
      } catch (error) {
        console.error("Error fetching month data:", error);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, isTotal, tab]
  );

  const fetchDayData = useCallback(
    async (date: Date) => {
      if (isTotal) return;

      const selectedYear = getYear(date);
      const selectedMonth = getMonth(date) + 1;
      const selectedDay = date.getDate();

      setLoading(true);
      try {
        const res = await getDayMajorTournamentList({
          year: selectedYear,
          month: selectedMonth,
          day: selectedDay,
        });
        setSortedSelectedListData(res);
      } catch (error) {
        console.error("Error fetching day data:", error);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, isTotal, tab]
  );

  // 월 변경 시 데이터 로드
  useEffect(() => {
    fetchMonthData(currentMonth);
  }, [currentMonth, fetchMonthData]);

  // 선택된 날짜 변경 시 데이터 로드
  useEffect(() => {
    if (!isTotal) {
      fetchDayData(selectedDate);
    }
  }, [selectedDate, isTotal, fetchDayData]);

  const renderDot = useCallback(
    (day: Date) => {
      if (!listData || listData.length === 0) return null;

      const tournamentsOnDay = listData.filter((item) => {
        if (!item.startAt || !item.endAt) return false;

        const startDate = new Date(item.startAt);
        const endDate = new Date(item.endAt);
        const currentDate = new Date(
          day.getFullYear(),
          day.getMonth(),
          day.getDate()
        );

        // 시간을 00:00:00으로 설정하여 날짜만 비교
        const start = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        );
        const end = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate()
        );

        return currentDate >= start && currentDate <= end;
      });

      if (tournamentsOnDay.length === 0) return null;

      const hasDomestic = tournamentsOnDay.some(
        (tournament) => tournament.competitionType === "DOMESTIC"
      );
      const hasInternational = tournamentsOnDay.some(
        (tournament) => tournament.competitionType === "INTERNATIONAL"
      );

      if (hasDomestic && hasInternational) {
        return (
          <DotWrapper>
            <RedDot />
            <GreenDot />
          </DotWrapper>
        );
      } else if (hasDomestic) {
        return (
          <DotWrapper>
            <RedDot />
          </DotWrapper>
        );
      } else if (hasInternational) {
        return (
          <DotWrapper>
            <GreenDot />
          </DotWrapper>
        );
      }

      return null;
    },
    [listData]
  );
  const renderDays = useCallback(() => {
    return (
      <Row>
        {daysOfWeek.map((day, index) => (
          <Column key={index}>{day}</Column>
        ))}
      </Row>
    );
  }, []);
  const renderCells = useCallback(() => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    const dateFormat = "d";
    const rows = [];

    let days: JSX.Element[] = [];
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const formattedDate = format(day, dateFormat);
        const cloneDay = new Date(day);

        days.push(
          <Cell
            key={day.toString()}
            selected={!isTotal && isSameDay(day, selectedDate)}
            disabled={!isSameMonth(day, monthStart)}
            onClick={() => onDateClick(cloneDay)}
          >
            <div className="background">
              <Number>{formattedDate}</Number>
              {renderDot(cloneDay)}
            </div>
          </Cell>
        );
        day = addDays(day, 1);
      }
      rows.push(<Row key={day.toString()}>{days}</Row>);
      days = [];
    }
    return <div>{rows}</div>;
  }, [currentMonth, selectedDate, isTotal, renderDot]);

  const onDateClick = useCallback((day: Date) => {
    setIsTotal(false);
    setSelectedDate(day);
  }, []);

  const changeYear = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newYear = parseInt(event.target.value, 10);
    setCurrentMonth(
      parse(`${newYear}-${getMonth(currentMonth) + 1}`, "yyyy-MM", new Date())
    );
  };

  const changeMonth = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newMonth = parseInt(event.target.value, 10);
    setIsTotal(true);
    setCurrentMonth(
      parse(`${getYear(currentMonth)}-${newMonth}`, "yyyy-MM", new Date())
    );
  };

  const renderSelectors = () => {
    const years = [];
    for (let i = getYear(new Date()) - 10; i <= getYear(new Date()) + 10; i++) {
      years.push(i);
    }

    return (
      <SelectRowWRapper>
        <Selectors>
          <div className="select-wrapper">
            <select value={getYear(currentMonth)} onChange={changeYear}>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}년
                </option>
              ))}
            </select>
          </div>
          <div className="select-wrapper">
            <select value={getMonth(currentMonth) + 1} onChange={changeMonth}>
              {months.map((month: any, index: number) => (
                <option key={index} value={index + 1}>
                  {month}
                </option>
              ))}
            </select>
          </div>
        </Selectors>
        <div
          className="total-button"
          onClick={() => {
            setCurrentMonth(currentMonth);
            setIsTotal(true);
          }}
        >
          이달 전체보기
        </div>
      </SelectRowWRapper>
    );
  };

  return (
    <Wrapper>
      <CalendarWrapper>
        {renderSelectors()}
        {renderDays()}
        {renderCells()}
      </CalendarWrapper>
      <DotInfoWrapper>
        <div className="info">
          <RedDot />
          국내 대회
        </div>
        <div className="info">
          <GreenDot />
          국제 대회
        </div>
      </DotInfoWrapper>
      <TournamentWrapper>
        <TournamentHeaderWrapper>
          <div className="title">토너먼트 정보</div>
          {!isTotal ? (
            <div className="date">
              {`${getYear(selectedDate)}년 ${
                getMonth(selectedDate) + 1
              }월 ${selectedDate.getDate()}일 `}
              <b
                style={{ position: "relative", display: "block", top: "-1px" }}
              >
                {selectedListData.length}개
              </b>
              {` 진행중`}
            </div>
          ) : (
            <div className="date">
              {`${getYear(currentMonth)}년 ${getMonth(currentMonth) + 1}월 `}
              <b
                style={{ position: "relative", display: "block", top: "-1px" }}
              >
                {selectedListData.length}개
              </b>
              {` 진행중`}
            </div>
          )}
        </TournamentHeaderWrapper>
        <TabWrapper>
          <TabButton
            $selected={tab === "DOMESTIC"}
            onClick={() => setTab("DOMESTIC")}
          >
            국내 대회
          </TabButton>
          <TabButton
            $selected={tab === "INTERNATIONAL"}
            onClick={() => setTab("INTERNATIONAL")}
          >
            국제 대회
          </TabButton>
        </TabWrapper>
        <div className="body">
          {selectedListData.map((item) => (
            <TournamentItem
              key={`${item.id || ""}-${item.startAt}-${item.endAt}`}
              data={item}
            />
          ))}
        </div>
      </TournamentWrapper>
    </Wrapper>
  );
};

export default MTournamentSchedule;
