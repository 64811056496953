import { useEffect, useRef } from "react";
const NaverMaps = (window as any).naver.maps;
export const useLocationMarker = (
  mapRef: any,
  latitude: number,
  longitude: number,
  accuracy: number
) => {
  const myLocMarkerRef = useRef<any>();

  useEffect(() => {
    if (!mapRef.current) return;

    if (myLocMarkerRef.current) {
      myLocMarkerRef.current.setMap(null);
    }

    if (accuracy !== -1) {
      const markerContent = `
        <div class="marker" style="width: 50px; height: 50px; filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.2))"> 
         <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="18" cy="18" r="18" fill="#6436E7" fill-opacity="0.2"/>
          <circle cx="18" cy="18" r="17.5" stroke="#8359F7" stroke-opacity="0.5"/>
          <circle cx="18" cy="18" r="9" fill="white"/>
          <circle cx="18" cy="18" r="5" fill="#6436E7"/>
         </svg>
        </div>
      `;

      myLocMarkerRef.current = new NaverMaps.Marker({
        position: new NaverMaps.LatLng(latitude, longitude),
        map: mapRef.current,
        icon: {
          content: markerContent,
          size: new NaverMaps.Size(22, 35),
          anchor: new NaverMaps.Point(11, 35),
        },
      });
    }
  }, [latitude, longitude, accuracy, mapRef.current]);

  return myLocMarkerRef;
};
