import { PremiumStoreListWrapper } from "../Style/PremiumStoreStyle";
import { useSearchContext } from "../Hook/SearchContext";
import { PremiumStoreItem } from "./PremiumStoreItem";

export const PremiumStoreList = () => {
  const { showStoreList, premiumList } = useSearchContext();

  return (
    <PremiumStoreListWrapper show={showStoreList}>
      <div className="title">추천 프리미엄 홀덤펍</div>
      <div className="list">
        <div className="inner">
          {premiumList.map((item, index) => (
            <PremiumStoreItem key={index} item={item}></PremiumStoreItem>
          ))}
        </div>
      </div>
    </PremiumStoreListWrapper>
  );
};
