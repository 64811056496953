import React from "react";
import styled from "styled-components";
import { Job } from "../../../api/job";
import { useHistory } from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding: 0px 16px 20px;

  > .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    > .title {
      color: #000;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: -0.4px;
    }

    > .more {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;
      cursor: pointer;

      > img {
        width: 16px;
        height: 16px;
      }

      > span {
        color: var(--Black-200, #b7b7b7);
        text-align: right;
        font-family: Pretendard;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.26px;
      }
    }
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;

const Item = styled.div`
  width: 100%;
  height: 94px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  > .top {
    display: flex;
    flex-direction: column;
    gap: 2px;

    > .name {
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.24px;
    }

    > .place {
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.2;
      letter-spacing: -0.2px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 24px; /* line-height * 2줄 */
    }
  }

  > .bottom {
    color: var(--Black-400, #444);
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.24px;
  }
`;

const JobOpening = ({ list }: { list: Job[] }) => {
  const history = useHistory();
  const displayedJobs = list.slice(0, 6); // 최대 6개만 표시

  return (
    <Wrapper>
      <div className="header">
        <div className="title">실시간 채용</div>
        <div
          className="more"
          onClick={() => {
            history.push("/recruit");
          }}
        >
          <img src="/image-web/home/more.svg" alt="more" />
          <span>더보기</span>
        </div>
      </div>
      <GridContainer>
        {displayedJobs.map((item, index) => (
          <Item
            key={index}
            onClick={() => {
              history.push(`/recruit/detail/${item.id}/pub`);
            }}
          >
            <div className="top">
              <div className="name">{item.cafeName}</div>
              <div className="place">{item.place}</div>
            </div>
            <div className="bottom">{`시급 : ${item.hourlyPay.toLocaleString()}원`}</div>
          </Item>
        ))}
      </GridContainer>
    </Wrapper>
  );
};

export default JobOpening;
