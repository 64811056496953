import React from "react";
import { SearchWrapper } from "../Style/SearchStyles";
import { useSearchContext } from "../Hook/SearchContext";

interface SearchLayoutProps {
  children: React.ReactNode;
  showFilter: boolean;
  showLocationFilter: boolean;
  setShowLocationFilter: (show: boolean) => void;
  cityText: string;
  showStoreList: boolean;
}

export const SearchLayout: React.FC<SearchLayoutProps> = ({
  children,
  showFilter,
  showLocationFilter,
  setShowLocationFilter,
  cityText,
  showStoreList,
}) => {
  const { premiumList, storeList, setSelectedStore } = useSearchContext();
  return (
    <SearchWrapper scrollLock={showFilter || showLocationFilter}>
      <div id="map">
        <div
          className="location"
          id="city"
          onClick={() => {
            setShowLocationFilter(true);
            setSelectedStore(null);
          }}
        >
          {cityText}
          <img src="/image-web/search/Icon/down_arrow.svg" />
        </div>
      </div>
      <div className="count">{`${
        storeList.length + premiumList.length
      }개 운영중`}</div>
      {children}
    </SearchWrapper>
  );
};
