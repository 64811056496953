import styled from "styled-components";
import { TournamentScheduleItemInterface } from "../../../api/tournament";
import moment from "moment";
import { useHistory } from "react-router-dom";
const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
  gap: 12px;
  > img {
    width: 60px;
    height: 60px;
    border-radius: 8px;
  }

  > .info {
    display: flex;
    flex-direction: column;
    flex: 1;
    > .header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      > .title {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.32px;
      }

      > .doing {
        display: flex;
        padding: 4px 6px;
        height: 19px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        flex-shrink: 0;
        border-radius: 16.667px;
        border: 1px solid var(--Purple-100, #f0eaff);
        color: var(--Purple-300, #6436e7);
        font-family: Pretendard;
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        > img {
          stroke-width: 1.2px;
          stroke: var(--Purple-300, #6436e7);
        }
      }
    }

    > .place {
      margin-top: 8px;
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 13px;
      font-weight: 700;
      line-height: 15.51px;
      letter-spacing: -0.02em;
      text-align: left;
    }

    > .date {
      margin-top: 3px;
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.26px;
    }
  }
`;
const TournamentItem = (props: { data: TournamentScheduleItemInterface }) => {
  const data = props.data;
  const now = new Date();
  const startDate = new Date(data.startAt);
  const endDate = new Date(data.endAt);
  const history = useHistory();

  let statusText = "";
  let statusClass = "";

  if (now < startDate) {
    statusText = "진행 예정";
    statusClass = "upcoming";
  } else if (now >= startDate && now <= endDate) {
    statusText = "진행중";
    statusClass = "ongoing";
  } else {
    statusText = "마감";
    statusClass = "ended";
  }
  return (
    <ItemWrapper
      onClick={() => history.push("/tournament/detail/major/" + data.id)}
    >
      <img src={data.image} />
      <div className="info">
        <div className="header">
          <div className="title">{data.title}</div>
          <div className="doing">
            {statusText}
            <img src="/image-web/Icon/rightArrow.svg" />
          </div>
        </div>
        <div className="place">{data.place}</div>
        <div className="date">
          {moment(data.startAt).format("YYYY년 MM월 DD일 HH:mm")} ~{" "}
          {moment(data.endAt).format("YYYY년 MM월 DD일 HH:mm")}
        </div>
      </div>
    </ItemWrapper>
  );
};

export default TournamentItem;
