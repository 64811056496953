import { atom, selector } from "recoil";
import { Cafe } from "../api/types";

export const searchState = atom({
  key: "search",
  default: "",
});

export const allStoresState = atom<Cafe[]>({
  key: "allStores",
  default: [],
});

// 파생된 프리미엄/일반 스토어 리스트
export const premiumStoreListState = selector<Cafe[]>({
  key: "premiumStoreList",
  get: ({ get }) => {
    const stores = get(allStoresState);
    return stores.filter((store) => store.pubType === "PREMIUM");
  },
});

export const regularStoreListState = selector<Cafe[]>({
  key: "regularStoreList",
  get: ({ get }) => {
    const stores = get(allStoresState);
    return stores.filter((store) => store.pubType !== "PREMIUM");
  },
});
