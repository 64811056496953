import styled from "styled-components";
import { ArticleResponse } from "../../../api/dashboard";
import { useHistory } from "react-router-dom";
import moment from "moment";
const Wrapper = styled.div`
  width: 100%;
  padding: 0 17px;
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  > .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > .title {
      color: #000;

      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: -0.4px;
    }

    > .more {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;
      cursor: pointer;

      > img {
        width: 16px;
        height: 16px;
      }

      > span {
        color: var(--Black-200, #b7b7b7);
        text-align: right;
        font-family: Pretendard;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.26px;
      }
    }
  }

  > .list {
    margin-top: 12px;
    widht: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;
const Item = styled.div`
  widht: 100%;
  padding-bottom: 6px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  > .title {
    overflow: hidden;
    color: var(--Black-400, #444);
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.24px;
    height: 14px;
    flex: 1;
  }

  > .info {
    display: flex;
    flex-direction: column;

    gap: 2px;
    > .name {
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.24px;
    }

    > .time {
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;
      display: flex;
      justify-content: flex-end;
    }
  }
`;
const Article = (props: { list?: ArticleResponse[] }) => {
  const list = props.list;
  const history = useHistory();

  return (
    <Wrapper>
      <div className="header">
        <div className="title">핸드 게시판</div>
        <div
          className="more"
          onClick={() => {
            history.push("/article");
          }}
        >
          <img src="/image-web/home/more.svg" alt="more" />
          <span>더보기</span>
        </div>
      </div>
      {list && list.length > 0 && (
        <div className="list">
          {list.map((item, index) => (
            <Item onClick={() => history.push(`/article/detail/${item.id}`)}>
              <div className="title">{item.title}</div>
              <div className="info">
                <div className="name">{item.authorNickname}</div>
                <div className="time">{moment(item.createdAt).fromNow()}</div>
              </div>
            </Item>
          ))}
        </div>
      )}
    </Wrapper>
  );
};

export default Article;
