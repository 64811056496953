import styled from "styled-components";

export const MenuInfoWrapper = styled.div`
  position: relative;
  width: calc(100% - 32px);
  left: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 84px;
  > div {
    position: relative;
    width: 100%;
    .title-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      > .title {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 20px;
        font-weight: 700;
        line-height: 23.87px;
        letter-spacing: -0.02em;
        text-align: left;
      }
      > .button {
        cursor: pointer;
        color: var(--Black-200, #b7b7b7);
        text-align: right;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.28px;
      }
    }

    > .menu-list {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0px;
      padding-bottom: 150px;

      > .no-menu {
        width: 100%;
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        color: var(--Black-400, #444);
        grid-column: 1 / -1;
      }
    }
  }
`;

export const RestaurantWrapper = styled.div`
  position: relative;
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  > img {
    flex-shrink: 0;
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
  }
  > .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    > .name {
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 600;
      line-height: 19.09px;
      letter-spacing: -0.02em;
      text-align: left;
    }
    > .menu-row {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 4px;
      > .menu {
        position: relative;
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.71px;
        letter-spacing: -0.02em;
        text-align: left;

        > span {
          font-family: Pretendard;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.71px;
          letter-spacing: -0.02em;
          text-align: left;
        }
      }
      > .price {
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 700;
        line-height: 16.71px;
        letter-spacing: -0.02em;
        text-align: left;
        margin-left: 4px;
      }
    }
  }
`;
