import { useEffect, useState } from "react";
import {
  communityDashboard,
  CommunityDashboardResponse,
} from "../../../api/dashboard";
import styled from "styled-components";
import { MEDIA_DESKTOP } from "../../../hooks/useScreenOrientation";
import { useHistory } from "react-router-dom";
import Article from "./Article";
import JobOpening from "./JobOpening";
import Market from "./Market";
import Best from "./Best";
const Wrapper = styled.div`
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 69px;
  .content {
    img {
      width: 100%;
    }
  }
`;
const Header = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background: white;
  z-index: 11;
  flex-direction: row;

  @media ${MEDIA_DESKTOP} {
    display: none;
  }
  .left {
    display: flex;
    align-items: center;
    .close {
      cursor: pointer;
      width: 24px;
      height: 24px;
      @media ${MEDIA_DESKTOP} {
        display: none;
      }
    }

    .title {
      margin-left: 5px;
      color: #444;
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 600;

      @media ${MEDIA_DESKTOP} {
        font-size: 24px;
        font-weight: 700;
      }
    }
  }
  .right {
    img {
      width: 20px;
      height: 20px;
    }
  }
`;
const Community = () => {
  const [dashboard, setDashboard] = useState<CommunityDashboardResponse | null>(
    null
  );
  const history = useHistory();
  const getBoards = async () => {
    const data = await communityDashboard();
    setDashboard(data);
  };

  useEffect(() => {
    getBoards();
  }, []);

  return (
    <Wrapper>
      <Header>
        <div className="left">
          <div
            className="close"
            onClick={() => {
              history.goBack();
            }}
          >
            <img
              src="/image-web/Icon/Back.svg"
              id="커뮤니티 뒤로가기"
              alt="close"
            />
          </div>
          <div className="title">커뮤니티</div>
        </div>
        <div className="right"></div>
      </Header>
      <div className="content">
        <img alt="banner" src="/image-web/community/banner.jpg"></img>
        {(dashboard?.hotArticle || dashboard?.hotSecondHandMarket) && (
          <Best
            hotArticle={dashboard?.hotArticle}
            hotSecondHandMarket={dashboard?.hotSecondHandMarket}
            hotJobOpening={dashboard?.hotJobOpening}
          />
        )}
        {dashboard?.articleList && <Article list={dashboard?.articleList} />}
        {dashboard?.jobOpeningList && (
          <JobOpening list={dashboard?.jobOpeningList} />
        )}
        {dashboard?.secondHandList && (
          <Market list={dashboard.secondHandList} />
        )}
      </div>
    </Wrapper>
  );
};

export default Community;
