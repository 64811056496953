import React from "react";
import styled from "styled-components";

const InfoMenuWrapper = styled.div<{ $tabCount: number }>`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  padding: 4px;
  width: calc(100% - 40px);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 45px;
  background: rgba(47, 51, 50, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  height: 40px;
  z-index: 101;
`;

const MenuContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

const Background = styled.div<{ $index: number; $tabCount: number }>`
  position: absolute;
  width: ${({ $tabCount }) => `calc(100% / ${$tabCount})`};
  height: 100%;
  border-radius: 45px;
  background: rgba(100, 54, 231, 1);
  transition: transform 0.5s ease;
  transform: translateX(${({ $index }) => `${100 * $index}%`});
`;

const MenuItem = styled.div<{ $isSelected?: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: ${({ $isSelected }) => ($isSelected ? "700" : "400")};
  color: ${({ $isSelected }) => ($isSelected ? "white" : "#808080")};
  transition: all 0.5s;
  cursor: pointer;
  z-index: 1;
`;

export type InfoMenuType = "detail" | "restaurant" | "location" | "review";

interface InfoMenuProps {
  showRestaurantTab: boolean;
  currentMenu: InfoMenuType;
  onMenuClick: (menu: InfoMenuType) => void;
  reviewCount: number;
}

const PremiumInfoMenu: React.FC<InfoMenuProps> = ({
  showRestaurantTab,
  currentMenu,
  onMenuClick,
  reviewCount,
}) => {
  const tabCount = showRestaurantTab ? 4 : 3;

  const getMenuIndex = (menu: InfoMenuType): number => {
    switch (menu) {
      case "detail":
        return 0;
      case "location":
        return 1;
      case "restaurant":
        return 2;
      case "review":
        return showRestaurantTab ? 3 : 2;
      default:
        return 0;
    }
  };

  const menuItems: Array<{ id: InfoMenuType; label: string }> = [
    { id: "detail" as const, label: "상세정보" },
    { id: "location" as const, label: "위치" },
    ...(showRestaurantTab
      ? [{ id: "restaurant" as const, label: "주변맛집" }]
      : []),
    { id: "review" as const, label: `리뷰(${reviewCount})` },
  ];

  return (
    <InfoMenuWrapper $tabCount={tabCount}>
      <MenuContainer>
        <Background $index={getMenuIndex(currentMenu)} $tabCount={tabCount} />
        {menuItems.map((item) => (
          <MenuItem
            key={item.id}
            $isSelected={currentMenu === item.id}
            onClick={() => onMenuClick(item.id)}
          >
            {item.label}
          </MenuItem>
        ))}
      </MenuContainer>
    </InfoMenuWrapper>
  );
};

export default PremiumInfoMenu;
