import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../StoreDetail/StoreContext";
import useUserInfo from "../../../../hooks/useUserInfo";
import { Restaurant } from "../../../../api/types";
import { MenuInfoWrapper, RestaurantWrapper } from "./styles/NearByRestaurants";
import { openNewWindow } from "../../../../utils/common";

const MenuInfo = () => {
  const [list, setList] = useState<Restaurant[]>([]);
  const [autoList, setAutoList] = useState<Restaurant[]>([]);
  const { user } = useUserInfo();
  const { data, update } = useContext(StoreContext);

  useEffect(() => {
    setList(data.restaurant ?? []);
  }, [data]);

  return (
    <>
      <MenuInfoWrapper>
        <div className="recommended">
          <div className="title-row">
            <div className="title">펍 추천 맛집</div>
          </div>
          <div className="menu-list">
            {list.length === 0 && (
              <div className="no-menu">등록된 가게가 없습니다.</div>
            )}
            {list.map((restaurant, index) => {
              return (
                <RestaurantWrapper
                  key={index}
                  onClick={() => {
                    if (restaurant?.link) openNewWindow(restaurant.link);
                  }}
                >
                  <img
                    src={restaurant.imageUrl ?? ""}
                    alt={restaurant.restaurantName}
                  />
                  <div className="info">
                    <div className="name">{restaurant.restaurantName}</div>
                    <div className="menu-row">
                      <div className="menu">
                        대표메뉴 <span>{`${restaurant.menu}`}</span>
                      </div>
                      <div className="price">
                        {`${restaurant.menuPrice.toLocaleString()}원`}
                      </div>
                    </div>
                  </div>
                </RestaurantWrapper>
              );
            })}
          </div>
        </div>
      </MenuInfoWrapper>
    </>
  );
};
export default MenuInfo;
