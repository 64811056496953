import styled from "styled-components";
import { SecondHandMarketResponse } from "../../../api/second-hand-market";
import { useHistory } from "react-router-dom";
import { userState } from "../../../recoil/auth";
import { useRecoilState } from "recoil";
import { ArticleResponse } from "../../../api/dashboard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import moment from "moment";
import { Job } from "../../../api/job";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > .header {
    margin-top: 16px;
    padding: 0 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > .title {
      color: #000;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: -0.4px;
    }
  }

  > .list {
    width: 100%;
    padding: 12px 16px;

    .swiper-slide {
      width: 200px !important; // 슬라이드 너비 고정
    }
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;
  width: 200px; // 너비 고정
  color: white;
  padding: 16px;
  border-radius: 8px;

  .top {
    .title {
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 700;
      line-height: 19.09px;
      letter-spacing: -0.02em;
      text-align: left;
      margin-bottom: 4px;

      // 2줄 제한과 말줄임표 설정
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word; // 긴 단어 처리
      max-height: 38.18px; // line-height * 2줄
    }
    .price {
      font-family: Pretendard;
      font-size: 12px;
      font-weight: 700;
      line-height: 14.32px;
      letter-spacing: -0.02em;
      text-align: left;
    }
  }

  .bottom {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    > .profile {
      position: relative;
      border-radius: 30px;
      width: 30px;
      height: 30px;
    }

    > .info {
      display: flex;
      flex-direction: column;
      gap: 4px;
      flex: 1;
      > .name {
        color: white;
        font-family: Pretendard;
        font-size: 12px;
        font-weight: 700;
        line-height: 14.32px;
        letter-spacing: -0.02em;
        text-align: left;
        text-overflow: ellipsis;
      }

      > .time {
        color: var(--Black-300, #ffffff);
        font-family: Pretendard;
        font-size: 10px;
        font-weight: 400;
        line-height: 11.93px;
        letter-spacing: -0.02em;
        text-align: left;
      }
    }
    > .arrow {
      position: relative;
      width: 20px;
      height: 20px;
    }
  }
`;

const HotArticle = styled(Item)`
  background: rgba(95, 70, 167, 1);
`;

const HotRecruit = styled(Item)`
  background: rgba(142, 8, 8, 1);
`;

const HotSecondMarket = styled(Item)`
  background: rgba(72, 75, 234, 1);
`;

const arrowUrl = "/image-web/button/arrow/black_right_circle.svg";

const Best = (props: {
  hotSecondHandMarket?: SecondHandMarketResponse;
  hotArticle?: ArticleResponse;
  hotJobOpening?: Job;
}) => {
  const hotSecond = props.hotSecondHandMarket;
  const hotArticle = props.hotArticle;
  const hotJobOpening = props.hotJobOpening;
  const history = useHistory();
  const [user, setUser] = useRecoilState(userState);

  return (
    <Wrapper>
      <div className="header">
        <div className="title">{user?.nickname}님을 위한</div>
        <div className="title">가장 뜨거운 베스트 게시물 ‍🔥</div>
      </div>
      <Swiper
        slidesPerView="auto"
        spaceBetween={8}
        className="list"
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        {hotArticle && (
          <SwiperSlide key={0}>
            <HotArticle
              onClick={() => {
                history.push(`/article/detail/${hotArticle.id}`);
              }}
            >
              <div className="top">
                <div className="title">{hotArticle.title}</div>
              </div>
              <div className="bottom">
                <img
                  className="profile"
                  alt="프로필 이미지"
                  src={
                    hotArticle.userProfile ||
                    "https://dfesoodpx4jgd.cloudfront.net/user/default_profile.png"
                  }
                />
                <div className="info">
                  <div className="name">{hotArticle?.authorNickname}</div>
                  <div className="time">
                    {moment(hotArticle?.createdAt).fromNow()}
                  </div>
                </div>
                <img className="arrow" alt="바로가기" src={arrowUrl} />
              </div>
            </HotArticle>
          </SwiperSlide>
        )}
        {hotJobOpening && (
          <SwiperSlide key={1}>
            <HotRecruit
              onClick={() => {
                history.push(`/second-hand-market/detail/${hotJobOpening.id}`);
              }}
            >
              <div className="top">
                <div className="title">{hotJobOpening.cafeName}</div>
                <div className="price">
                  시급:{`${hotJobOpening.hourlyPay.toLocaleString()}원`}
                </div>
              </div>
              <div className="bottom">
                <img
                  className="profile"
                  alt="프로필 이미지"
                  src={
                    "https://dfesoodpx4jgd.cloudfront.net/user/default_profile.png"
                  }
                />
                <div className="info">
                  <div className="name">{hotSecond?.username}</div>
                  <div className="time">
                    {moment(hotSecond?.createdAt).fromNow()}
                  </div>
                </div>
                <img className="arrow" alt="바로가기" src={arrowUrl} />
              </div>
            </HotRecruit>
          </SwiperSlide>
        )}
        {hotSecond && (
          <SwiperSlide key={2}>
            <HotSecondMarket
              onClick={() => {
                history.push(`/second-hand-market/detail/${hotSecond.id}`);
              }}
            >
              <div className="top">
                <div className="title">{hotSecond.title}</div>
                <div className="price">
                  가격:{`${hotSecond.price.toLocaleString()}원`}
                </div>
              </div>
              <div className="bottom">
                <img
                  className="profile"
                  alt="프로필 이미지"
                  src={
                    "https://dfesoodpx4jgd.cloudfront.net/user/default_profile.png"
                  }
                />
                <div className="info">
                  <div className="name">{hotSecond?.username}</div>
                  <div className="time">
                    {moment(hotSecond?.createdAt).fromNow()}
                  </div>
                </div>
                <img className="arrow" alt="바로가기" src={arrowUrl} />
              </div>
            </HotSecondMarket>
          </SwiperSlide>
        )}
      </Swiper>
    </Wrapper>
  );
};

export default Best;
