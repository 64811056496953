import { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useStoreData } from "./useStoreData";
import { enqueueSnackbar } from "notistack";
import { ReviewDataResponse } from "../../api/types";
import { useRecoilValue } from "recoil";
import { currentTimeState } from "../../recoil/app";

export const useStoreDetail = (id: string) => {
  const history = useHistory();
  const currentTime = useRecoilValue(currentTimeState);
  const { data, fetchData } = useStoreData();
  const [rate, setRate] = useState(0);
  const [hasOpenChat, setHasOpenChat] = useState(true);
  const [showEdit, setShowEdit] = useState(false);
  const [showWrite, setShowWrite] = useState(false);
  const [editReviewData, setEditReviewData] =
    useState<ReviewDataResponse | null>(null);

  const handleUpdate = useCallback(() => {
    if (id) {
      const cafeId = Number(id);
      fetchData(cafeId)
        .then((res) => {
          if (res.reviewData.length > 0) {
            const totalScore = res.reviewData.reduce(
              (sum, review) => sum + review.score,
              0
            );
            setRate(totalScore / res.reviewData.length);
          }
          setHasOpenChat(!!res.openChatURL);
        })
        .catch((e: any) => {
          enqueueSnackbar("펍 정보를 가져올 수 없습니다: " + e.message, {
            variant: "error",
          });
          history.goBack();
        });
    } else {
      history.goBack();
    }
  }, [id, fetchData, history]);

  useEffect(() => {
    handleUpdate();
  }, [handleUpdate]);

  return {
    data,
    rate,
    hasOpenChat,
    showEdit,
    setShowEdit,
    showWrite,
    setShowWrite,
    editReviewData,
    setEditReviewData,
    handleUpdate,
    currentTime,
  };
};
