import styled from "styled-components";
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`;
export const CalendarWrapper = styled.div`
  width: 100%;
  padding 16px;
  margin: 4px auto;
`;

export const Row = styled.div`
  display: flex;
`;

export const Column = styled.div<{ flex?: number }>`
  width: 100%;
  flex: ${(props) => props.flex || 1};
  text-align: center;

  color: #000;
  padding: 13px;
  font-family: "Prestige Elite Std";
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
`;

export const Cell = styled.div<{ selected?: boolean; disabled?: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  > .background {
    width: 37px;
    height: 37px;
    flex-shrink: 0;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    color: ${(props) =>
      props.disabled ? "#7A7A7A" : props.selected ? "#FFFFFF" : "#000000"};

    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    font-family: Pretendard;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 145.455% */
    background-color: ${(props) =>
      props.disabled ? "" : props.selected ? "#6436E7" : "#F0F0F0"};
  }
`;

export const Number = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const DotWrapper = styled.div`
  display: flex;
  gap: 2px;
  justify-content: center;
  height: 4px;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
`;
export const RedDot = styled.div`
  width: 4px;
  height: 4px;
  background: rgba(255, 0, 0, 1);
  border: 0.5px solid rgba(0, 0, 0, 0.5);
  border-radius: 50%;
`;
export const GreenDot = styled.div`
  width: 4px;
  height: 4px;
  background: rgba(217, 255, 0, 1);
  border: 0.5px solid rgba(0, 0, 0, 0.5);
  border-radius: 50%;
`;

export const SelectRowWRapper = styled.div`
  display: flex;
  justify-content: space-between;
  .total-button {
    width: 86px;
    height: 30px;
    padding: 9px 8px 9px 12px;
    border-radius: 15px;
    background: rgba(240, 240, 240, 1);
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.32px;
    text-align: left;
  }
`;
export const Selectors = styled.div`
  display: flex;
  gap: 12px;

  .select-wrapper {
    position: relative;
    display: inline-block;
    width: fit-content;
  }

  select {
    padding: 8px 22px 8px 12px;
    border-radius: 15px;
    border: 1px solid var(--Black-200, #b7b7b7);
    color: var(--Black-500, #202020);
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    appearance: none;
    background-color: white;
    width: 100%;
    cursor: pointer;
  }

  .select-wrapper::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 8px;
    width: 14px;
    height: 14px;
    background-image: url("/image-web/Icon/down-arrow.svg");
    background-size: cover;
    background-repeat: no-repeat;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;
export const FlexRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export const DotInfoWrapper = styled(FlexRowWrapper)`
  justify-content: flex-start;
  gap: 10px;
  margin-left: 16px;
  margin-bottom: 10px;
  > .info {
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.32px;
    text-align: left;
    color: rgba(122, 122, 122, 1);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
`;
export const TournamentHeaderWrapper = styled(FlexRowWrapper)`
  justify-content: space-between;
  > .title {
    color: #000;

    font-family: Pretendard;
    font-size: 17.181px;
    font-style: normal;
    font-weight: 600;
    line-height: 25.771px;
  }

  > .date {
    color: var(--Black-300, #808080);
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.28px;
    line-height: 25.771px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
    > .count {
      font-weight: 700;
    }
  }
`;

export const TournamentWrapper = styled.div`
  margin-top: 10px;
  padding: 0 16px 30px;
  > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  > .body {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`;

export const TabWrapper = styled(FlexRowWrapper)`
  margin-top: 12px;
  width: calc(100%);
  justify-content: space-between;
  gap: 8px;
`;
export const TabButton = styled.div<{ $selected: boolean }>`
  flex: 1;
  padding: 9px 8px 9px 12px;
  gap: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background-color: ${(props) => (props.$selected ? "#6436E7" : "#FFFFFF")};
  ${(props) =>
    props.$selected ? "" : "border: 1px solid rgba(128, 128, 128, 1);"}
  color: ${(props) => (!props.$selected ? "#7A7A7A" : "#FFFFFF")};
`;
