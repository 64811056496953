import { useEffect, useState } from "react";
import { ArticleResponse } from "../../../api/dashboard";
import styled from "styled-components";
import { tr } from "date-fns/locale";
import { getBoardBest, getNotices } from "../../../api/article";
import moment from "moment";
import { useHistory } from "react-router-dom";
const Wrapper = styled.div`
  padding-top: 88px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;

  > .data-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 15px;

    > .item {
      width: 100%;
      padding: 5px 16px 8px;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #f0f0f0;

      > .comment-wrapper {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
        > img {
          width: 10px;
          height: 10px;
        }

        > .count {
          color: #a991ec;

          font-feature-settings: "liga" off, "clig" off;
          font-family: "Plus Jakarta Sans";
          font-size: 10px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
        }
      }
      > .info {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;

        > .title-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          > img {
            width: 16px;
            height: 16px;
          }

          > .title {
            width: 100%;
            overflow: hidden;
            color: var(--Purple-300, #6436e7);
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: Pretendard;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.24px;
          }

          > .title.no {
            color: var(--Black-400, #444);
          }
        }

        > .bottom {
          display: flex;
          flex-direction: row;
          gap: 3px;
          align-items: center;
          > .text {
            color: var(--Black-300, #808080);
            font-family: Pretendard;
            font-size: 8px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 11.2px */
            letter-spacing: -0.16px;
          }
          > .bar {
            width: 0.5px;
            height: 3px;
            background-color: #adadad;
          }
        }
      }
    }
  }
`;
const BestPage = () => {
  const [dataList, setDataList] = useState<ArticleResponse[]>([]);
  const history = useHistory();
  const getNotice = async () => {
    try {
      const response = await getBoardBest();
      setDataList(response);
    } catch (e) {}
  };

  useEffect(() => {
    getNotice();
  }, []);
  return (
    <Wrapper>
      {dataList.length > 0 && (
        <div className="data-list">
          {dataList.map((data, index) => (
            <div
              className="item"
              onClick={() => history.push(`/article/detail/${data.id}`)}
            >
              <div className="info">
                <div className="title-wrapper">
                  <img
                    src={
                      /<img\s+[^>]*src="[^"]*"[^>]*>/.test(data.content)
                        ? "/image-web/article/image.svg"
                        : "/image-web/article/no-image.svg"
                    }
                  />
                  <div
                    className={`title${
                      /<img\s+[^>]*src="[^"]*"[^>]*>/.test(data.content)
                        ? ""
                        : " no"
                    }`}
                  >
                    {data.title}
                  </div>
                </div>
                <div className="bottom">
                  <div className="text"> {data.authorNickname}</div>
                  <div className="bar"></div>
                  <div className="text">{moment(data.createdAt).fromNow()}</div>
                  <div className="bar"></div>
                  <div className="text"> {"조회 " + data.viewCount}</div>
                  <div className="bar"></div>
                  <div className="text"> {"추천 " + data.likeCount}</div>
                </div>
              </div>
              <div className="comment-wrapper">
                <img src="/image-web/article/comment.svg" />
                <div className="count">{data.commentCount}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </Wrapper>
  );
};

export default BestPage;
