import {
  PremiumStoreInfoBottomWrapper,
  PremiumStoreInfoTopWrapper,
  PremiumStoreInfoWrapper,
  PremiumStoreTournamentInfoWrapper,
} from "../Style/PremiumStoreStyle";
import { useSearchContext } from "../Hook/SearchContext";
import { Cafe, TimerInfoState } from "../../../../api/types";
import { getDistanceKm } from "../../../../utils/common";
import { calculateTimes } from "../../../../utils/timer";
import { useEffect, useState } from "react";
interface PremiumStoreItemProps {
  item: Cafe;
}

export const PremiumStoreItem: React.FC<PremiumStoreItemProps> = ({ item }) => {
  const {
    history,
    latitude,
    longitude,
    handleMoreOption,
    showStoreList,
    currentTime,
  } = useSearchContext();
  const [timer, setTimer] = useState<TimerInfoState | null>(null);
  const handleMoreOptions = (e: React.MouseEvent) => {
    handleMoreOption(item);
    e.stopPropagation(); // 이벤트 버블링 방지
  };

  useEffect(() => {
    if (item.pubTournamentList.length < 1) return;
    if (!item.pubTournamentList[0].timerDto) return;
    setTimer(
      calculateTimes(
        item.pubTournamentList[0].timerDto!,
        item.pubTournamentList[0].structure
      )
    );
  }, [currentTime]);

  return (
    <PremiumStoreInfoWrapper
      onClick={() => {
        if (showStoreList) {
          history.push(`/store/${item.id}`);
        }
      }}
    >
      <div className="thumbnail">
        <img
          alt="cover"
          className="cover"
          src={
            item?.images[0]
              ? item.images[0].imageUrl
              : "https://dfesoodpx4jgd.cloudfront.net/cafe/default.png"
          }
        />
      </div>
      <PremiumStoreInfoTopWrapper>
        <div className="info">
          <div className="title-row">
            <div className="name">{item.cafeName} </div>
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={handleMoreOptions}
            >
              <rect width="22" height="22" rx="11" fill="#D9D9D9" />
              <path
                d="M6.12212 9.5C5.33066 9.5 4.68311 10.175 4.68311 11C4.68311 11.825 5.33066 12.5 6.12212 12.5C6.91357 12.5 7.56113 11.825 7.56113 11C7.56113 10.175 6.91357 9.5 6.12212 9.5Z"
                fill="#444444"
              />
              <path
                d="M11 9.5C10.2086 9.5 9.56104 10.175 9.56104 11C9.56104 11.825 10.2086 12.5 11 12.5C11.7915 12.5 12.4391 11.825 12.4391 11C12.4391 10.175 11.7915 9.5 11 9.5Z"
                fill="#444444"
              />
              <path
                d="M15.878 9.5C15.0865 9.5 14.439 10.175 14.439 11C14.439 11.825 15.0865 12.5 15.878 12.5C16.6694 12.5 17.317 11.825 17.317 11C17.317 10.175 16.6694 9.5 15.878 9.5Z"
                fill="#444444"
              />
            </svg>
          </div>
          <div className="review-box">
            <img
              alt="premium mark"
              className="premium"
              src="/image-web/store/premium.png"
            />
            <div className="review-score">
              {item.score && (item.score === 0 ? 0 : item.score.toFixed(1))}
            </div>
            <div className="review-count">{`리뷰 ${item.reviewCount}`}</div>
          </div>
        </div>
      </PremiumStoreInfoTopWrapper>
      <PremiumStoreInfoBottomWrapper>
        <div className="info">
          <div className="address">
            {item.newAddress} {item.detailAddress}
          </div>
          <div className="info-row">
            <div className="item">
              <img
                alt="icon"
                className="icon"
                src="/image-web/Icon/Heart/small-white.svg"
              />
              <span className="text">{item.likeCount}</span>
            </div>
            <div className="item">
              <img
                alt="icon"
                className="icon"
                src="/image-web/Icon/Map%20pin/small-white.svg"
              />
              <span className="text">
                {getDistanceKm(latitude, longitude, item.lat, item.lon)} km
              </span>
            </div>
          </div>
        </div>
        <PremiumStoreTournamentInfoWrapper isIng={timer ? true : false}>
          {timer && timer.currentLevel.type !== "BREAK" && (
            <div className="timer">
              <div className="lv"> {`LV ${timer.currentLevel.level}`}</div>
              <div className="time">
                {`${timer.remainingTimeInCurrentLevel}`}
              </div>
            </div>
          )}
          {timer && timer.currentLevel.type === "BREAK" && (
            <div className="timer">
              <div className="time">BREAK</div>
            </div>
          )}
          {!timer && (
            <>
              <div>OFF</div>
              <div className="state">게임준비중</div>
            </>
          )}
          {timer && <div className="state">현재진행중</div>}
        </PremiumStoreTournamentInfoWrapper>
      </PremiumStoreInfoBottomWrapper>
    </PremiumStoreInfoWrapper>
  );
};
